export class RoutePath {
    static readonly initial = '/'
    static readonly urlTool  = '/tools/url'
    static readonly htmlTool  = '/tools/base/html'
    static readonly jsonTool  = '/tools/base/json'
    static readonly jsTool  = '/tools/base/js'
    static readonly cssTool  = '/tools/base/css'
    static readonly xmlTool  = '/tools/base/xml'
    static readonly baseTool  = '/tools/base/:mp'
    static readonly classic  = '/tools/text/classic'
    static readonly passwordGenerate  = '/tools/password/generate'
    static readonly bcryptGenerate  = '/tools/password/bcrypt'
    static readonly timeStamp  = '/tools/time-stamp'
    static readonly uuid  = '/tools/uuid'
    static readonly gitTool  = '/tools/git'
}